<template>
  <template v-if="show">
    <div
      class="rounded-md bg-red-500 dark:bg-red-50 p-4 mb-5"
      :class="className"
    >
      <div class="flex">
        <div class="flex-shrink-0">
          <ExclamationIcon
            class="h-5 w-5 text-red-100 dark:text-red-400"
            aria-hidden="true"
          />
        </div>
        <div class="ml-3">
          <p class="text-sm font-medium text-red-100 dark:text-red-800">
            {{ text }}
          </p>
        </div>
        <div class="ml-auto pl-3">
          <div class="-mx-1.5 -my-1.5">
            <button
              @click="show = !show"
              type="button"
              class="
                inline-flex
                bg-red-500
                dark:bg-red-50
                rounded-md
                p-1.5
                text-red-100
                dark:text-red-500
                hover:text-red-200
                dark:hover:text-red-600
              "
            >
              <span class="sr-only">Dismiss</span>
              <XIcon class="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import { ExclamationIcon, XIcon } from "@heroicons/vue/solid";

export default {
  name: "PopupAlertRelative",
  components: {
    ExclamationIcon,
    XIcon,
  },
  props: ["text", "className"],
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style>
</style>